$(function () {
  //////アニメーション
  $(window).scroll(function () {
    $('.anime').each(function () {
      var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 50) {
        $(this).addClass('anime_active');
      } else {
        $(this).removeClass('anime_active');
      }
    });
  });
  //表示させるwidthを指定
  var ua = navigator.userAgent;
  var viewport = document.querySelector("meta[name=viewport]");
  if (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    viewport.setAttribute("content", "width=device-width,initial-scale=1");
  } else {
    viewport.setAttribute("content", "width=1080");
  }
  //////ページトップ
	/*
  var topBtn = $("#page-top");
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $("body,html").animate({
        scrollTop: 0,
      },
      1000
    );
    return false;
  });
	*/

  //////ナビゲーション
  $(".menu_trigger").on("click", function () {
    $(this).toggleClass("active");
    $(".header_nav_contents").slideToggle();
    $(".header_contents").toggleClass("active");
  });
  var $win = $(window),
    animationClass = "is_animation";
  $win.on("load scroll", function () {
    var value = $(this).scrollTop();
    if (value > 100) {
      $(".header").addClass("is_animation");
    } else {
      $(".header").removeClass("is_animation");
    }
  });
});

$(".header_nav_contents a").on("click", function () {
  if (window.innerWidth <= 768) {
    $(".menu_trigger").click();
  }
});
$(window).on("resize", function () {
  if ("none" == $(".header_nav_contents").css("display")) {
    $(".header_nav_contents").attr("style", "");
  }
});


//////////////////

//more btn
$('.item_more_btn').on('click',function(){
	var id = $(this).attr('id'),
			num = $(this).data('num'),
			num = num - 1;
	if($(this).hasClass('close')){
		$('.'+id).children().eq(num).nextAll().hide();
		$(this).removeClass('close');
	}else{
		$('.'+id).children().eq(num).nextAll().fadeIn(400);
		$(this).addClass('close');
	}
});


//modal
//about modal
$('.nomad_album_list .sec_item').on('click',function(){
	var modal = $('#nomad_album .sec_item_modal_wrap'),
			img = $(this).find('.sec_item_img').html(),
			txt = $(this).find('.sec_item_caption').html();
	modal.find('.sec_item_modal_img').html(img);
	modal.find('.sec_item_modal_caption').html(txt);

	$('.sec_item_modal_back').fadeIn(300);
	modal.fadeIn(300).css('display','flex');
	return false;
});

//works modal
$('.works_list .sec_item--modal').on('click',function(){
	var modal = $('#works_list_sec .sec_item_modal_wrap'),
			img_url = $(this).data('img'),
			txt = $(this).data('txt'),
			ttl = $(this).find('.sec_item_ttl').html(),
			img = '<img src="'+img_url+'" alt="'+ttl+'">';
	if(!img_url){
		modal.find('.sec_item_modal_img').addClass('noimage');
		modal.find('.sec_item_modal_img').html('');
	}else{
		modal.find('.sec_item_modal_img').html(img);
	}
	if($(this).data('img2')){
		var img2_url = $(this).data('img2'),
				img2 = '<img src="'+img2_url+'" alt="'+ttl+'">';
		modal.find('.sec_item_modal_img_wrap').append('<figure class="sec_item_modal_img">'+img2+'</figure>');
		$('.sec_item_modal_img_wrap').slick({
			speed: 600,
			infinite: false,
		});
		$('.sec_item_modal_img_wrap').slick('setPosition');
		setTimeout(function(){
			var modal_h =  $('.sec_item_modal_img_wrap').outerHeight(true);
			$('.sec_item_modal_img_wrap .slick-track').css({'height': modal_h+'px'});
		},10);
	}
	modal.find('.sec_item_modal_ttl').html(ttl);
	modal.find('.sec_item_modal_caption').html(txt);

	$('.sec_item_modal_back').fadeIn(300);
	modal.fadeIn(300).css('display','flex');
	return false;
});


//modal close
$('.sec_item_modal_back, .sec_item_modal_close').on('click',function(){
	$('.sec_item_modal_wrap').fadeOut(200);
	$('.sec_item_modal_back').fadeOut(200);
	setTimeout(function(){
		$('.sec_item_modal_img.noimage').removeClass('noimage');
		if($('.sec_item_modal_img_wrap').hasClass('slick-slider')){
			$('.sec_item_modal_img_wrap').slick('unslick');
			$('.sec_item_modal_img').remove();
			$('.sec_item_modal_img_wrap').html('<figure class="sec_item_modal_img"></figure>')
		}
	},200);
});

